<section class="step-explanation" *ngIf="completedSteps && !toggleMenu">
  <div class="side-nav" *ngIf="!mobileVersion">
    <app-side-nav
      [mobileVersion]="mobileVersion"
      [completedSteps]="completedSteps"
      [initialDate]="objToNaWiz?.dataContratacao"
      (currentStep)="changeCurrentStep($event)"
    ></app-side-nav>
  </div>

  <div *ngIf="mobileVersion" class="mobile-menu">
    <img
      (click)="clickOnBack()"
      src="../assets/general/to-na-wiz/to-na-wiz/mobile-redesign/arrow-back.svg"
      alt="back"
    />
    <img
      (click)="clickOnMenu()"
      src="../assets/general/to-na-wiz/to-na-wiz/mobile-redesign/menu-icon.svg"
      alt="back"
    />
  </div>

  <div *ngIf="currentStep === 0" class="full-explanation">
    <div class="explanation">
      <div class="top-section">
        <h1>Aceite da proposta</h1>
        <button (click)="clickOnBack()">tela inicial</button>
      </div>
      <div class="text-content">
        <p>
          Parabéns pelo seu desempenho e aprovação no processo seletivo! Estamos
          super felizes de te ver por aqui (:
        </p>
        <p>
          Agora que você já aceitou a nossa proposta de trabalho, é hora de
          começar a sua jornada como Wizzer! Clique em
          <strong>Próxima Etapa</strong> para saber os próximos passos.
        </p>
      </div>
    </div>
    <div class="lower-single-button">
      <button class="forward" (click)="changeExplanation(+1)">
        <span *ngIf="!mobileVersion; else next_mobile">Próxima etapa</span>
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-right.svg"
          alt="proxima"
        />
      </button>
    </div>
  </div>

  <div *ngIf="currentStep === 1" class="full-explanation">
    <div class="explanation">
      <div class="top-section">
        <h1>Entregue seus documentos</h1>
        <button (click)="clickOnBack()">tela inicial</button>
      </div>
      <div class="text-content">
        <p>
          Até aqui você esteve sob os cuidados do time de Atração e Seleção da Wiz Co, mas a partir de agora
          quem cuidará do seu processo é Centro de Operação de Pessoas - COP. Eles são responsáveis por
          coletar toda a sua documentação para a admissão. Para fazer isso da melhor maneira possível eles
          usam uma plataforma externa, a Yube.
        </p>
        <p>
          Você já deve ter recebido um e-mail da Yube. Caso não tenha, espere até que o time do COP faça o
          seu cadastro e logo após clique no botão abaixo para acessar a Yube e dar continuidade ao seu
          processo de admissão!
        </p>
        <p>
          Na Yube, você vai preencher formulários sobre vários benefícios, como o vale refeição, vale transporte
          e seguro de vida. Além disso, é por lá que você vai enviar documentos pessoais importantes para a
          sua admissão.
        </p>
        <div class="buttons margin-24">
          <div
            (click)="redirectTo(links.yube)"
            class="button-template yube-button"
          >
            Acessar a Yube
          </div>
          <div
            *ngIf="
              completedSteps[currentStep].completed === false;
              else completed_step_button
            "
            (click)="completeStep()"
            class="button-template complete-button"
          >
            Concluir etapa
          </div>
        </div>
      </div>
    </div>
    <div class="lower-buttons">
      <button class="back" (click)="changeExplanation(-1)">
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-left.svg"
          alt="anterior"
        />
        <span *ngIf="!mobileVersion; else back_mobile">Etapa anterior</span>
      </button>
      <button class="forward" (click)="changeExplanation(+1)">
        <span *ngIf="!mobileVersion; else next_mobile">Próxima etapa</span>
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-right.svg"
          alt="proxima"
        />
      </button>
    </div>
  </div>

  <div *ngIf="currentStep === 2" class="full-explanation">
    <div class="explanation">
      <div class="top-section">
        <h1>Entenda os seus benefícios</h1>
        <button (click)="clickOnBack()">tela inicial</button>
      </div>
      <div class="text-content">
        <p>
          Agora é a hora de entender um pouco mais sobre os benefícios que você terá por
          fazer parte do grupo Wiz Co — tanto os custeados e administrados pela
            companhia, quanto os que são custeados por você e administrados por parceiros.
        </p>
        <p>
          Acreditamos que benefícios são grande parte da nossa proposta de trabalho,
          porque eles também são uma forma direta de remuneração e complementam a
          nossa proposta salarial.
        </p>
        <p>
          Clique no botão abaixo para ter acesso às regras para contratação do benefício,
          datas de pagamento, os aplicativos que devem ser baixados para uso de cada
          benefício e parcerias exclusivas que temos.
        </p>
        <p>Esta etapa será concluída ao clicar no botão abaixo.</p>
        <div class="margin-24">
          <div
            (click)="completeStepWithRedirect(benefitsRoute)"
            id="conhecer-beneficios"
            class="button-template yube-button"
          >
            Conhecer benefícios
          </div>
        </div>
      </div>
    </div>
    <div class="lower-buttons">
      <button class="back" (click)="changeExplanation(-1)">
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-left.svg"
          alt="anterior"
        />
        <span *ngIf="!mobileVersion; else back_mobile">Etapa anterior</span>
      </button>
      <button class="forward" (click)="changeExplanation(+1)">
        <span *ngIf="!mobileVersion; else next_mobile">Próxima etapa</span>
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-right.svg"
          alt="proxima"
        />
      </button>
    </div>
  </div>

  <div *ngIf="currentStep === 3" class="full-explanation">
    <div class="explanation">
      <div class="top-section">
        <h1>Conheça o Grupo Wiz Co</h1>
        <button (click)="clickOnBack()">tela inicial</button>
      </div>
      <div class="text-content custom-scroll">
        <p>
          Somos uma corretora completa de seguros, especializada em
          bancassurance, e distribuidora de consórcios e crédito.
        </p>
        <p>
          Atuamos com ampla oferta de serviços para extrair valor de diferentes canais, gerando oportunidades e potencializando negócios com
          dinamismo, sinergia e expertise no mercado. Possuímos mais de 50 anos de atuação e, ao longo dos últimos anos, firmamos parcerias
          estratégicas com mais de 20 instituições, entre elas: Inter, Bmg, BRB, Paraná Banco e Grupo Omni
        </p>
        <p>
          Atualmente, temos {{ unitsFilters.length }} Unidades de Negócio e há também as Unidades Corporativas. Essas são as áreas que dão sustentação e apoio para
          que as demais possam ficar 100% focadas em negócio e vendas. Além de impulsionar as entregas na ponta, o Corporativo é respon sável
          por manter os métodos e a cultura da companhia
        </p>
        <table class='units-table'>
          <tr>
            <th>Unidades De Negócio</th>
            <th>Unidades Corporativas</th>
          </tr>
          <tr>
            <td>Wiz Corporate</td>
            <td>Gente e Cultura</td>
          </tr>
          <tr>
            <td>Wiz Concept</td>
            <td>Financeiro</td>
          </tr>
          <tr>
            <td>Wiz Conseg</td>
            <td>Comunicação e Marketing</td>
          </tr>
          <tr>
            <td>Wiz Parceiros</td>
            <td>Tecnologia</td>
          </tr>
          <tr>
            <td>Promotiva</td>
            <td>Estratégia</td>
          </tr>
          <tr>
            <td>Inter Seguros</td>
            <td></td>
          </tr>
          <tr>
            <td>BMG Corretora
            </td>
            <td></td>
          </tr>
          <tr>
            <td>BRB Seguros</td>
            <td></td>
          </tr>
          <tr>
            <td>Paraná Seguros</td>
            <td></td>
          </tr>
          <tr>
            <td>Omni 1</td>
            <td></td>
          </tr>
          <tr>
            <td>Polishop Seguros</td>
            <td></td>
          </tr>
        </table>
        <p>
          Resumindo: a Wiz Co é um grupo composto por unidades de negócio e unidades corporativas que juntas formam
          a companhia.
        </p>
        <p>
          Muita coisa né?
        </p>
        <p>
          Sabemos que você deve estar cheio de dúvidas, mas mais para frente você vai entender melhor sobre a Wiz,
          nossas Unidades e nossa história.
        </p>
        <p>
          Enquanto isso, que tal seguir a gente nas redes sociais e dar uma olhada nos nossos sites?
        </p>

        <ul>
          <li class="links-1">
            <a

              [href]="links.linkedIn"
              target="_blank"
              rel="noreferrer noopener"
              >Linkedin</a
            >
          </li>
          <li class="links-2">
            <a

              [href]="links.instagram"
              target="_blank"
              rel="noreferrer noopener"
              >Instagram</a
            >
          </li>
          <li class="links-3">
            <a

              [href]="links.instaCarreiras"
              target="_blank"
              rel="noreferrer noopener"
              >Instagram de carreiras</a
            >
          </li>
          <li class="links-4">
            <a

              [href]="links.spotify"
              target="_blank"
              rel="noreferrer noopener"
              >Spotify</a
            >
          </li>
          <li class="links-5">
            <a

              [href]="links.facebook"
              target="_blank"
              rel="noreferrer noopener"
              >Facebook</a
            >
          </li>
          <li class="links-6">
            <a

              [href]="links.site"
              target="_blank"
              rel="noreferrer noopener"
              >Site institucional</a
            >
          </li>
          <li class="links-7">
            <a
              [href]="links.ri"
              target="_blank"
              rel="noreferrer noopener"
              >Site de Relacionamento com o Investidor</a
            >
          </li>
        </ul>
        <br />
        <div
          (click)="redirectTo(links.wizTreinamento)"
          (click)="completeStep()"
          class="button-template yube-button"
        >
          Ir para o treinamento
        </div>
      </div>
    </div>
    <div class="lower-buttons">
      <button class="back" (click)="changeExplanation(-1)">
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-left.svg"
          alt="anterior"
        />
        <span *ngIf="!mobileVersion; else back_mobile">Etapa anterior</span>
      </button>
      <button class="forward" (click)="changeExplanation(+1)">
        <span *ngIf="!mobileVersion; else next_mobile">Próxima etapa</span>
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-right.svg"
          alt="proxima"
        />
      </button>
    </div>
  </div>

  <div *ngIf="currentStep === 4" class="full-explanation">
    <div class="explanation">
      <div class="top-section">
        <h1>Descubra a nossa Cultura</h1>
        <button (click)="clickOnBack()">tela inicial</button>
      </div>
      <div class="text-content">
        <p>
          Cultura é um conjunto de ações, valores e crenças que definem e
          direcionam um grupo de pessoas. Em um ambiente corporativo, é ela que
          orienta a forma como a organização irá se estruturar, lidar com
          pessoas e conduzir seus negócios.
        </p>

        <p>
          Podemos perceber os elementos de uma cultura corporativa de maneira
          mais intensa ou mais branda, mas a verdade é que eles estão presentes
          a todo o momento e têm um impacto fortíssimo na maneira que a empresa
          funciona.
        </p>
        <p>
          Aqui na Wiz, temos o WizWay, que é a consolidação do jeito Wiz de ser e por isso hoje entendemos que os
          nossos pilares são o cérebro da cultura e a sustentação para tudo o que acontece e deve acontecer.
        </p>
        <p>
          Eles representam nossa maneira de pensar e nos organizar. São a base, a fundação e dão direcionamento
          para o que queremos ter e viver na companhia
        </p>
        <p>Os pilares nos orientam para os comportamentos que queremos ter na Wiz Co!
        </p>
        <p>
          Esta etapa será concluída ao clicar no botão abaixo.
        </p>
        <br />
        <div
          (click)="completeStepWithRedirect(links.wizWay)"
          class="button-template yube-button"
        >
          Saiba mais sobre o Wiz Way
        </div>
      </div>
      <br />
    </div>
    <div class="lower-buttons">
      <button class="back" (click)="changeExplanation(-1)">
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-left.svg"
          alt="anterior"
        />
        <span *ngIf="!mobileVersion; else back_mobile">Etapa anterior</span>
      </button>
      <button class="forward" (click)="changeExplanation(+1)">
        <span *ngIf="!mobileVersion; else next_mobile">Próxima etapa</span>
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-right.svg"
          alt="proxima"
        />
      </button>
    </div>
  </div>

  <div *ngIf="currentStep === 5" class="full-explanation">
    <div class="explanation">
      <div class="top-section">
        <h1>Prepare-se para o seu primeiro dia</h1>
        <button (click)="clickOnBack()">tela inicial</button>
      </div>
      <div class="text-content">
        <p>
          No dia {{ objToNaWiz.dataContratacao | date : 'dd/MM/yyyy' }} você irá assumir sua posição como
          {{ objToNaWiz.cargo }}!
        </p>
        <p>
          Para te ajudar no seu primeiro dia, aqui estão algumas dicas:
        </p>
        <ul>
          <li>
            Se organize para conseguir chegar no horário combinado para o seu
            primeiro dia. Se tiver dúvidas do que fazer, mande um e-mail para o
            seu gestor e entenda como será esse primeiro dia.
          </li>
          <li>
            Anote todas as dúvidas que tiver para poder saná-las no momento
            oportuno.
          </li>
          <li>
            Aproveite seu primeiro dia para fazer as trilhas de desenvolvimento
            na Wizity, nossa universidade corporativa. Essa é uma das melhores
            formas de se integrar na companhia e na nossa cultura.
          </li>
          <li>
            Curta muito esse momento! Converse com o seu gestor, seu padrinho e
            todos da equipe que te ajudarão durante o processo de ambientação.
          </li>
        </ul>
        <br />
        <div
          *ngIf="
            completedSteps[currentStep].completed === false;
            else completed_step_button
          "
          (click)="completeStep()"
          class="button-template complete-button"
        >
          Concluir etapa
        </div>
      </div>
    </div>
    <div class="lower-buttons">
      <button class="back" (click)="changeExplanation(-1)">
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-left.svg"
          alt="anterior"
        />
        <span *ngIf="!mobileVersion; else back_mobile">Etapa anterior</span>
      </button>
      <button class="forward" (click)="changeExplanation(+2)">
        <span *ngIf="!mobileVersion; else next_mobile">Próxima etapa</span>
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-right.svg"
          alt="proxima"
        />
      </button>
    </div>
  </div>

  <div *ngIf="currentStep === 7" class="full-explanation">
    <div class="explanation">
      <div class="top-section">
        <h1>Mostre seu orgulho em se tornar Wizzer</h1>
        <button (click)="clickOnBack()">tela inicial</button>
      </div>
      <div class="text-content">
        <p>
          Parabéns, agora você é um Wizzer! Nós estamos super orgulhosos de ter você no nosso time!
        </p>
        <p>
          Que tal mostrar o orgulho que você tem em fazer disso tudo? Para isso, você pode:
        </p>
        <p>
          Atualizar o seu Linkedin com o seu novo cargo e a sua nova empresa.
        </p>
        <div class="margin-24">
          <div
            (click)="redirectTo(links.linkedInWizzer)"
            class="button-template yube-button"
          >
            Linkedin
          </div>
        </div>
        <p>Usar uma de nossas capas no Linkedin.</p>
        <div class="margin-24">
          <div
            (click)="completeStepWithRedirect(links.wizCovers)"
            class="button-template yube-button"
          >
            Baixar capas
          </div>
        </div>
        <p>
          Usar os planos de fundo da Wiz para você já entrar nas primeiras reuniões pelo Teams arrasando:
        </p>
        <div class="margin-24">
          <div
            (click)="completeStepWithRedirect(links.wizWallpapers)"
            class="button-template yube-button"
          >
            Baixar planos de fundo
          </div>
        </div>
        <p>Esta etapa será concluída ao baixar as capas ou planos de fundo.</p>
      </div>
    </div>

    <div class="lower-buttons">
      <button class="back" (click)="changeExplanation(-2)">
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-left.svg"
          alt="anterior"
        />
        <span *ngIf="!mobileVersion; else back_mobile">Etapa anterior</span>
      </button>
      <button class="forward" (click)="changeExplanation(+1)">
        <span *ngIf="!mobileVersion; else next_mobile">Próxima etapa</span>
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-right.svg"
          alt="proxima"
        />
      </button>
    </div>
  </div>

  <div *ngIf="currentStep === 8" class="full-explanation">
    <div class="explanation">
      <div class="top-section">
        <h1>Resgate seus mimos de novo Wizzer</h1>
        <button (click)="clickOnBack()">tela inicial</button>
      </div>
      <div class="text-content">
        <p>Para te desejar boas-vindas, preparamos um presente pra você!</p>

        <p>Para resgatar clique no link abaixo e preencha o formulário! E ai, pronto! É só
          esperar você receberá seu kit em casa ou no escritório!</p>

        <p>Lembrando que é necessário ter alguém no endereço que receba os correios
          e assine o recebimento. É de responsabilidade do Wizzer acompanhar o
          código de rastreio (que será enviado no e-mail indicado) e fazer a retirada do
          kit caso ele volte para a agência dos Correios.</p>

        <br />

        <div class="buttons margin-24">
          <div
            (click)="redirectTo(links.lobbyTech)"
            class="button-template yube-button"
          >
            Quero meu mimo!
          </div>
          <div
            *ngIf="
                completedSteps[currentStep].completed === false;
                else completed_step_button
              "
            (click)="completeStep()"
            class="button-template complete-button"
          >
            Concluir etapa
          </div>
        </div>
      </div>
    </div>
    <div class="lower-buttons">
      <button class="back" (click)="changeExplanation(-1)">
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-left.svg"
          alt="anterior"
        />
        <span *ngIf="!mobileVersion; else back_mobile">Etapa anterior</span>
      </button>
      <button class="forward" (click)="changeExplanation(+1)">
        <span *ngIf="!mobileVersion; else next_mobile">Próxima etapa</span>
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-right.svg"
          alt="proxima"
        />
      </button>
    </div>
  </div>

  <div *ngIf="currentStep === 9" class="full-explanation">
    <div class="explanation">
      <div class="top-section">
        <h1>Conheça nossas ferramentas</h1>
        <button (click)="clickOnBack()">tela inicial</button>
      </div>
      <div class="text-content custom-scroll">
        <p>Essas são as ferramentas trabalho comuns a todos os Wizzers:</p>
        <h2>Teams</h2>
        <p>
          É o principal responsável por facilitar e dar velocidade à comunicação
          no dia a dia. Use para se conectar com o seu time ou com outras áreas.
        </p>
        <mat-divider></mat-divider>
        <br />
        <h2>Outlook</h2>
        <p>
          É a ferramenta de e-mail corporativo que usamos para falar tanto
          internamente quanto externamente.
        </p>
        <mat-divider></mat-divider>
        <br />
        <h2>betanet</h2>
        <p>
          É o nosso portal com todos os links úteis que você vai precisar aqui
          na Wiz. Lá você você verá as últimas notícias da empresa, recados do
          nosso CEO e as vagas que estão abertas. Também é por lá que você
          abrirá casos, que é a forma de solicitar várias coisas, como
          materiais, ferramentas e acessos.
        </p>
        <br />
        <div
          (click)="redirectTo(links.betanet)"
          class="button-template yube-button"
        >
          Acesse a betanet
        </div>
        <br />
        <mat-divider></mat-divider>
        <br />
        <h2>Wizity</h2>
        <p>
          É a nossa Universidade Corporativa! Ela está recheada de conteúdos
          interessantes para seu autodesenvolvimento. Cursos online, vídeos,
          fóruns... tudo pensado para o seu crescimento! E ainda tem mais: todos
          os cursos que você fizer na plataforma geram moedas virtuais, que são
          os lamps.
        </p>
        <div
          (click)="redirectTo(links.wizity)"
          class="button-template yube-button"
        >
          Acesse a Wizity
        </div>
        <br />
        <mat-divider></mat-divider>
        <br />
        <h2>Portal LG</h2>
        <p>
          É o portal no qual você irá bater o seu ponto nos dias de trabalho,
          consultar o contracheque e pedir férias, seu companheiro de todos os
          dias.
        </p>
        <div (click)="redirectTo(links.lg)" class="button-template yube-button">
          Acesse a LG
        </div>
        <br />
        <mat-divider></mat-divider>
        <br />
        <h2>Qulture Rocks</h2>
        <p>
          Essa é a nossa ferramenta de gestão de desempenho, nela você poderá
          receber e enviar feedbacks e elogios para sua equipe, liderança e
          qualquer Wizzer que estiver por lá.
        </p>
        <div
          (click)="redirectTo(links.qultureRocks)"
          class="button-template yube-button"
        >
          Acesse a Qulture
        </div>
        <br />
        <mat-divider></mat-divider>
        <br />
        <p>
          Claro, cada cargo trabalha com algumas outras ferramentas que não
          estão nessa lista. Se você tiver alguma dúvida sobre ferramentas de
          trabalho, procure seu gestor ou padrinho!
        </p>
        <div
          *ngIf="
            completedSteps[currentStep].completed === false;
            else completed_step_button
          "
          (click)="completeStep()"
          class="button-template complete-button"
        >
          Concluir etapa
        </div>
        <br />
      </div>
    </div>
    <div class="lower-buttons">
      <button class="back" (click)="changeExplanation(-1)">
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-left.svg"
          alt="anterior"
        />
        <span *ngIf="!mobileVersion; else back_mobile">Etapa anterior</span>
      </button>
      <button class="forward" (click)="changeExplanation(+1)">
        <span *ngIf="!mobileVersion; else next_mobile">Próxima etapa</span>
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-right.svg"
          alt="proxima"
        />
      </button>
    </div>
  </div>

  <div *ngIf="currentStep === 10" class="full-explanation">
    <div class="explanation">
      <div class="top-section">
        <h1>Nossas ações de diversidade</h1>
        <button (click)="clickOnBack()">tela inicial</button>
      </div>
      <div class="text-content">
        <p>
          Aqui na Wiz um dos pilares da nossa cultura é a Equidade e Inclusão. Por isso, esperamos que todos os Wizzers, independente do posição ocupada, também
          sejam multiplicadores desse pilar respeitando todos os indivíduos e nos ajudando a promover mais equidade no dia a dia. Mas se o tema é novo pra você, não
          tem problema, a Trilha de Diversidade & Inclusão está disponível e nela você encontrará tudo que precisa saber para começar a nos ajudar nessa missão.
        </p>
        <div class="margin-24">
          <div
            (click)="redirectTo(coursesTerms.diversidadeInclusaoCurso)"
            class="button-template yube-button"
          >
            Trilha
          </div>
        </div>
        <p>
          E já que estamos falando em promover ambientes mais diversos e inclusivos nós criamos censo de Diversidade, é uma pesquisa obrigatória a todos os
          Wizzers. Clique aqui para responder:
        </p>
        <div class="margin-24">
          <div
            (click)="redirectTo(coursesTerms.diversidadeCenso)"
            class="button-template yube-button"
          >
            Censo
          </div>
        </div>
        <p>Sua resposta no Censo de Diversidade nos ajuda a entender mais sobre o nosso cenário atual, em quais frentes de diversidade precisamos melhorar para
          aumentar a representatividade internamente. Um detalhe importante é que todos os dados são criptografados, ou seja, somente o time responsável pela
          aplicação é que consegue identificar os respondentes. Caso ainda assim, você não se sinta confortável em responder alguma pergunta, basta optar pelas
          opções "prefiro não declarar"</p>
        <p>
          Esta etapa será concluída ao realizar a trilha na Wizity e o censo.
        </p>
      </div>
    </div>
    <div class="lower-buttons">
      <button class="back" (click)="changeExplanation(-1)">
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-left.svg"
          alt="anterior"
        />
        <span *ngIf="!mobileVersion; else back_mobile">Etapa anterior</span>
      </button>
      <button class="forward" (click)="changeExplanation(+1)">
        <span *ngIf="!mobileVersion; else next_mobile">Próxima etapa</span>
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-right.svg"
          alt="proxima"
        />
      </button>
    </div>
  </div>

  <div *ngIf="currentStep === 11" class="full-explanation">
    <div class="explanation">
      <div class="top-section">
        <h1>Assine alguns termos importantes</h1>
        <button (click)="clickOnBack()">tela inicial</button>
      </div>
      <div class="text-content custom-scroll">
        <p>
          Você faz parte de um time que valoriza método e disciplina. Alguns
          processos são importantes e necessários para garantirmos que esteja
          tudo certinho com a sua contratação na Wiz. Por isso, precisamos que
          você acesse os dois termos a seguir e que os assine online.
        </p>
        <h2>
          1. Termo de participação do treinamento de integridade e compromisso
          com o código de conduta ética da Wiz
        </h2>

        <p>
          Antes de tudo, você precisa participar do treinamento de Código de
          Conduta Ética na Wizity.
        </p>

        <div class="margin-24">
          <div
            (click)="redirectTo(coursesTerms.condutaEtica)"
            class="button-template yube-button"
          >
            Acesse o treinamento
          </div>
        </div>

        <p>
          Depois que terminar, é só assinar o termo confirmando que realizou o
          treinamento e leu a nossa cartilha. O termo também está no final do
          treinamento, então se já tiver assinado por lá, não precisa assinar de
          novo, ok?
        </p>

        <div class="margin-24">
          <div
            (click)="redirectTo(coursesTerms.termoCondutaEtica)"
            class="button-template yube-button"
          >
            Assine o termo
          </div>
        </div>

        <div *ngIf="checkWeFlex()">
          <h2>2. Modelo de trabalho</h2>
          <p>
            Como o seu modelo de trabalho é WE Flex, ou seja ficará 2 dias por
            semana fazendo home office, nós precisamos que você assine o termo
            de concordância com esse formato de trabalho. Para entender um pouco
            mais sobre o Programa WE acessa a nossa cartilha. Ah! Quando
            terminar de ler não esqueça de assinar o nosso termo afirmando que
            leu a cartilha.
          </p>

          <div
            (click)="redirectTo(coursesTerms.termoWEFlex)"
            class="button-template yube-button"
          >
            Assine o termo
          </div>
          <br />
          <br />
          <div
            (click)="redirectTo(coursesTerms.termoWEOffice)"
            class="button-template yube-button"
          >
            Assine o termo
          </div>
          <br />
        </div>

        <div *ngIf="!checkWeFlex()">
          <h2>2. Modelo de trabalho</h2>
          <p>
            Como o seu modelo de trabalho é
            {{ objToNaWiz?.tipoModeloDeTrabalho }}, nós precisamos que você leia
            a nossa cartilha do Programa WE para entender como você pode se
            adaptar da melhor maneira a esse novo formato de trabalho. Ah!
            Quando terminar de ler não esqueça de assinar o nosso termo
            afirmando que leu a cartilha.
          </p>

          <div class="terms">
            <div
              (click)="redirectTo(coursesTerms.termoWEOffice)"
              class="button-template yube-button"
            >
              Assine o termo
            </div>
          </div>
        </div>

        <p>
          Para concluir esta etapa você precisa assinar os termos acima! Ah,
          pode levar até 1 semana para o sistema reconhecer seu CPF após sua
          data de admissão :) Caso mesmo após uma semana você não consiga
          acessar, abra um caso na Intranet.
        </p>
      </div>
    </div>
    <div class="lower-buttons">
      <button class="back" (click)="changeExplanation(-1)">
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-left.svg"
          alt="anterior"
        />
        <span *ngIf="!mobileVersion; else back_mobile">Etapa anterior</span>
      </button>
      <button class="forward" (click)="changeExplanation(+1)">
        <span *ngIf="!mobileVersion; else next_mobile">Próxima etapa</span>
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-right.svg"
          alt="proxima"
        />
      </button>
    </div>
  </div>

  <div *ngIf="currentStep === 12" class="full-explanation">
    <div class="explanation">
      <div class="top-section">
        <h1>Compareça ao Wiz Welcome</h1>
        <button (click)="clickOnBack()">tela inicial</button>
      </div>
      <div class="text-content">
        <p>
          Adoramos unir as pessoas, mas isso pode ser um grande desafio na Wiz,
          já que estamos espalhados por todo o país.
        </p>
        <p>
          E como também topamos o desafio que vier e adoramos tecnologias, vamos
          unir os nossos novos Wizzers virtualmente para bater um papo sobre a
          empresa!
        </p>
        <p>
          Nesse dia você vai conhecer mais sobre a Wiz, a nossa área de gente,
          nossa Universidade Corporativa e outras informações importantes para o
          seu período de adaptação.
        </p>
        <!-- <p>Seu Wiz Welcome será dia {{objToNaWiz.dataIntegracao | date: 'dd/MM/yyyy'}}</p> -->
        <p>
          Fique ligado! Em breve você receberá o convite para esse evento por
          e-mail.
        </p>
      </div>
    </div>
    <div class="lower-buttons">
      <button class="back" (click)="changeExplanation(-1)">
        <img
          src="../assets/general/to-na-wiz/to-na-wiz/redesign/arrow-left.svg"
          alt="anterior"
        />
        <span *ngIf="!mobileVersion; else back_mobile">Etapa anterior</span>
      </button>
    </div>
  </div>

  <ng-template #back_mobile>
    <span>Anterior</span>
  </ng-template>

  <ng-template #next_mobile>
    <span>Próxima</span>
  </ng-template>

  <ng-template #completed_step_button>
    <div class="button-template completed-step-button">
      <img
        src="../assets/general/to-na-wiz/to-na-wiz/redesign/check_circle.svg"
        alt=""
      />
      <span class="green-text">Etapa Concluída</span>
    </div>
  </ng-template>
</section>

<div *ngIf="mobileVersion && toggleMenu" class="side-nav">
  <app-side-nav
    [mobileVersion]="mobileVersion"
    [completedSteps]="completedSteps"
    [initialDate]="objToNaWiz?.dataContratacao"
    (currentStep)="changeCurrentStep($event)"
    (backPressed)="menuBackPressed()"
  >
  </app-side-nav>
</div>
